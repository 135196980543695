<template>
    <div class="stock-watch ">
        <div class="top">
            <div class="title">
                库存监控
            </div>
            <div class="right-icon"></div>
        </div>
        <div class="stock-content">
            <div class="graph-panel-1">
                <el-carousel
                    :loop="false"
                    indicator-position="none"
                    :autoplay="false"
                    arrow="always"
                >
                    <!--                    数据分页，每页5条-->
                    <el-carousel-item v-for="(rows,index) in monitorList" :key="'monitorpage'+index">
                        <ul>
                            <li v-for="item in rows" :key="'lineMonitor'+index+item.stock_code">
                                <div class="name">
                                    {{ item.stock_no }}
                                </div>
                                <div class="view-box">
                                    <div class="value" :style="`color: ${item.color}`">
                                        {{ item.volume_theory }}
                                    </div>
                                    <div :class="['view-legend', item.bgClass]"></div>
                                    <!-- <div class="value">
                                        {{ item.volume_theory }}
                                    </div> -->
                                    <!-- <div class="split-val">
                                        {{ item.volume_warn }}
                                    </div> -->
                                </div>
                                <div class="jdt">
                                    <el-progress :percentage="item.percentage" :color="item.color" :show-text="false"></el-progress>
                                </div>
                                <div :class="['bottom-name', item.nameClass]" :title="item.stock_name">
                                    <span class="tag" :style="{background:item.color}"></span>
                                    {{ item.stock_name }}
                                </div>
                            </li>
                        </ul>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="graph-panel-2">
                <div class="graph-panel-2-1">
                    <el-carousel
                        ref="carousel2"
                        indicator-position="none"
                        :autoplay="false"
                        arrow="never"
                        :loop="false"
                    >
                        <el-carousel-item v-for="(rows,index) in lineMonitorList" :key="'lineMonitor'+index">
                            <div class="panel-item" v-for="item in rows" :key="'lineMonitor'+item.lineName">
                                <div class="panel-name">
                                    <span class="span1"></span>
                                    {{ item.lineName }}
                                    <span class="span2"></span>
                                </div>
                                <div class="panel-content">
                                    <ul class="material-list">
                                        <li v-for="(material,mIndex) in item.materials" :key="item.lineName+'material'+mIndex">
                                            <div class="name">
                                                {{ material.stock_no }}
                                            </div>
                                            <div class="view-box">
                                                <div :class="['view-legend', material.viewBgClass]"></div>
                                                <div class="value" :style="`color: ${material.color}`">
                                                    {{ material.volume_theory }}
                                                </div>
                                                <!-- <div class="split-val">
                                                    {{ material.volume_warn }}
                                                </div> -->
                                            </div>
                                            <div class="jdt">
                                                <el-progress :percentage="material.percentage" :color="material.color" :show-text="false">
                                                </el-progress>
                                            </div>
                                            <div :class="['bottom-name', material.nameClass]" :title="material.stock_name">
                                                <span class="tag" :style="{background:material.color}"></span>
                                                {{ material.stock_name }}
                                            </div>
                                        </li>
                                    </ul>
                                    <ul class="admixture-list">
                                        <template v-for="(admixture,aIndex) in item.admixtures">
                                            <li :key="item.lineName+'admixture'+aIndex">
                                                <div class="name">
                                                    {{ admixture.stock_no }}
                                                </div>
                                                <div class="view-box">
                                                    <div :class="['view-legend', admixture.viewBgClass]"></div>
                                                    <!-- <div class="view-legend-custom">
                                                        <div class="val-bg" :style="{height:admixture.percentage+'%'}"></div>
                                                        <div class="sanjiao"></div>
                                                        <div class="split-val" :style="{bottom:admixture.warnPercentage+'%'}">
                                                            {{ admixture.volume_warn }}
                                                        </div>
                                                    </div> -->
                                                    <div class="value" :style="`color: ${admixture.color}`">
                                                        {{ admixture.volume_theory }}
                                                    </div>
                                                </div>
                                                <div class="jdt">
                                                    <el-progress :percentage="admixture.percentage" :color="admixture.color" :show-text="false">
                                                    </el-progress>
                                                </div>
                                                <div :class="['bottom-name', admixture.nameClass]" :title="admixture.stock_name">
                                                    <span class="tag" :style="{background:admixture.color}"></span>
                                                    {{ admixture.stock_name }}
                                                </div>
                                            </li>
                                            <li v-if="aIndex!=item.admixtures.length">
                                                <div class="name"></div>
                                            </li>
                                        </template>
                                    </ul>
                                </div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="nav-panel" style="height: 0.6rem;">
                    <div :class="{'nav-btn':true,'canUse':lineSwiperActive[0]>0} " @click="handlePrevItem">
                        <i class="iconfont iconarrow2_left"></i>
                    </div>
                    <div class="nav-list">
                        <div>
                            <div
                                :class="{'nav-item':true,active:lineSwiperActive.includes(index)}"
                                v-for="(item,index) in lineList"
                                :key="item.line_name"
                            >
                                {{ index+1 }}
                            </div>
                        </div>
                    </div>
                    <div :class="{'nav-btn':true,'canUse':lineSwiperActive[1]<5/2} " @click="handleNextItem">
                        <i class="iconfont iconarrow2_right"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'stock-monitor-panel',
    data() {
        return {
            // 监控列表
            monitorList: [],
            // 按线路监控列表
            lineMonitorList: [],
            // 激活的面板索引
            lineSwiperActive: [0, 1],
            lineList: [],
            stationId: '',
        };
    },
    watch: {},
    methods: {
        updateData( lineList, stationId) {
            this.lineList = lineList;
            this.stationId = stationId;
            this.getStockMonitor();
        },
        // source_name: ""
        // stock_code: "023-F3-01-XN-01-002"
        // stock_name: "石灰岩砂(中砂)"
        // stock_no: "机制砂华能"
        // team_id: ""
        // team_name: ""
        // type_dic_code: "203"
        // volume_max: 6000
        // volume_theory: -5635.95
        // volume_warn: 1500
        // 料仓监控获取料仓信息
        getStockMonitor() {
            const enterpriseTenantId = this.$takeTokenParameters('TenantId');
            this.$axios
                .get(`/interfaceApi/procurement/stock/monitor/${this.stationId}?tenant_id=${enterpriseTenantId}`)
                .then(res => {
                    if (res) {
                        const lineMonitorList = [];
                        const monitorList = [];
                        res.map(item => {
                            if (item.volume_theory <= item.volume_warn) {
                                item.color = '#C14136';
                                item.bgClass = 'legend4';
                                item.viewBgClass = 'view-legend04';
                                item.nameClass = 'bottom-name4';
                            } else if (item.volume_theory <= item.volume_max / 2) {
                                item.color = '#DA9E13';
                                item.bgClass = 'legend3';
                                item.viewBgClass = 'view-legend03';
                                item.nameClass = 'bottom-name3';
                            } else if (item.volume_theory === item.volume_max) {
                                item.color = '#3CE6E4';
                                item.bgClass = 'legend1';
                                item.viewBgClass = 'view-legend01';
                                item.nameClass = 'bottom-name1';
                            } else {
                                item.color = '#3CE6E4';
                                item.bgClass = 'legend2';
                                item.viewBgClass = 'view-legend02';
                                item.nameClass = 'bottom-name2';
                            }
                            if (item.volume_theory > 0) {
                                item.percentage = Number((item.volume_theory / item.volume_max * 100).toFixed(2));
                            } else {
                                item.percentage = 0;
                            }
                            if (item.volume_warn > 0) {
                                item.warnPercentage = Number((item.volume_warn / item.volume_max * 100).toFixed(2));
                            } else {
                                item.warnPercentage = 0;
                            }
                            if (['203', '204'].includes(item.type_dic_code) ) {
                                // if (item.type_dic_code === '203') {
                                //     item.bgClass = 'legend1';
                                // } else if (item.type_dic_code === '204') {
                                //     item.bgClass = 'legend2';
                                // } else if (item.type_dic_code === '204') {
                                //     item.bgClass = 'legend2';
                                // }
                                monitorList.push(item);
                            } else {
                                lineMonitorList.push(item);
                            }
                        });
                        this.monitorList = this.formatPageData(monitorList, 5);
                        // 生产线数据处理
                        this.productionLineDataProcessing(lineMonitorList);
                    }
                })
                .catch(e => {
                    if (e.ErrorCode) {
                        this.$message.error(e.ErrorCode.Message);
                    }
                });
        },
        // 生产线数据处理
        productionLineDataProcessing(lineMonitorList) {
            const monitorMapping = {};
            lineMonitorList.forEach(item => {
                const team_ids = item.team_id.split(',');
                if (team_ids.length > 1) {
                    team_ids.forEach(teamId => {
                        if (monitorMapping[teamId]) {
                            monitorMapping[teamId].push(item);
                        } else {
                            monitorMapping[teamId] = [item];
                        }
                    });
                } else {
                    monitorMapping[item.team_id] = monitorMapping[item.team_id] || [];
                    monitorMapping[item.team_id].push(item);
                }
            });
            const resultList = [];
            this.lineList.forEach(line => {
                const result = {
                    lineName: line.line_name,
                    materials: [],
                    admixtures: [],
                };
                const list = monitorMapping[line.line_code] || [];
                list.forEach(item => {
                    if (item.type_dic_code !== '208') {
                        result.materials.push(item);
                    } else {
                        result.admixtures.push(item);
                    }
                });
                resultList.push(result);
            });
            this.lineMonitorList = this.formatPageData(resultList, 2);
        },
        // 数据分页
        formatPageData(list, pageSize) {
            // 总页数
            // eslint-disable-next-line radix
            const totalPage = parseInt((list.length + pageSize - 1) / pageSize);
            const result = [];
            for (let i = 0; i < totalPage; i++) {
                const rows = list.slice(i * pageSize, i * pageSize + pageSize );
                result.push(rows);
            }
            return result;
        },

        handlePrevItem() {
            if (this.lineSwiperActive[0] <= 0) {
                return;
            }
            this.$refs.carousel2.prev();
            const index1 = this.lineSwiperActive.shift();
            const showIndex1 = index1 - 2;
            const showIndex2 = index1 - 1;
            this.lineSwiperActive = [showIndex1, showIndex2];
        },
        handleNextItem() {
            if (this.lineSwiperActive[1] >= this.lineList.length - 1) {
                return;
            }
            this.$refs.carousel2.next();
            const index1 = this.lineSwiperActive.pop();
            const showIndex1 = index1 + 1;
            const showIndex2 = index1 + 2;
            this.lineSwiperActive = [showIndex1, showIndex2];
        },
    },
};
</script>

<style lang="stylus" scoped>
    .stock-watch
        display flex
        flex-wrap wrap
        width: 100%;
        height: 100%;
        >>>.el-carousel__arrow
            background #0AB9F9 !important
            width 22px !important
            height 22px !important
            color #000 !important
        >>>.el-carousel__arrow:hover
            width 30px !important
            height 30px !important
        .top
            width 100%;
            height 0.46rem;
            padding-left 0.2rem;
            display flex
            justify-content space-between
            align-items center
            background #11213B
            position relative
            background url("./../../../assets/images/leadingCockpit/title.png") no-repeat
            background-size 100% 100%;
            .query-box
                padding-right 0.9rem
            .right-icon
                position absolute
                right 0.1rem;
                top 0.2rem;
                width 1rem;
                height 0.22rem;
                background url("../images/nl.png") no-repeat
                background-size 100% 100%;
            .title
                font-size 0.16rem;
                color #fff;
                margin-left .24rem
                .iconfont
                    color #32C5FF
                    margin-right 0.12rem;
        .stock-content
            width 100%;
            height calc(100% - 0.46rem);
            padding 0.16rem;
            display flex
            flex-direction column
            .graph-panel-1
                width 100%;
                height 32%;
                background rgba(63, 116, 190, 0.2)
                .el-carousel
                    height 100%;
                    overflow hidden
                    >>> .el-carousel__container
                        height calc(100% - 0.15rem)
                        ul
                            box-sizing border-box
                            width 100%;
                            height 100%;
                            padding 0 0.1rem 0.1rem 0.1rem
                            display flex
                            background url("../images/one.png") no-repeat center;
                            background-size 100% 40%;
                            background-position: 0 80%;
                            li
                                width 20%;
                                height 100%;
                                padding 0 0.02rem;
                                display flex
                                flex-direction column
                                justify-content space-between
                                align-items center
                                position relative
                                .name
                                    width 100%;
                                    height 0.32rem;
                                    background rgba(63, 116, 190, 0.2);
                                    display flex
                                    justify-content center
                                    align-items center
                                    color rgba(255,255,255,0.6);
                                    font-size 0.14rem;
                                .bottom-name
                                    width 84%;
                                    height 0.21rem;
                                    padding-left 0.01rem;
                                    line-height 0.21rem;
                                    text-align center
                                    background #2C4C7B
                                    color rgba(255,255,255,0.6);
                                    font-size 0.14rem;
                                    position: relative;
                                    white-space nowrap
                                    text-overflow ellipsis
                                    overflow hidden
                                    &.bottom-name1
                                        background url("../images/text1.png") no-repeat center;
                                        background-size 100% 100%;
                                    &.bottom-name2
                                        background url("../images/text1.png") no-repeat center;
                                        background-size 100% 100%;
                                    &.bottom-name3
                                        background url("../images/text3.png") no-repeat center;
                                        background-size 100% 100%;
                                    &.bottom-name4
                                        background url("../images/text2.png") no-repeat center;
                                        background-size 100% 100%;
                                    // .tag
                                    //     display inline-block;
                                    //     width 0.5rem;
                                    //     height 0.5rem;
                                    //     background #21BC9A
                                    //     transform rotate(45deg)
                                    //     position: absolute;
                                    //     left -0.5rem;
                                    //     top -0.25rem;
                                .view-box
                                    box-sizing border-box
                                    width 1.35rem;
                                    height 1.45rem;
                                    margin 0.1rem 0;
                                    position relative
                                    .view-legend
                                        width 100%;
                                        height 100%;
                                        background url("../images/stock01.png") no-repeat center;
                                        background-size 100% 100%;
                                        position absolute
                                        left 0
                                        top 0
                                        z-index 1;
                                        &.legend1
                                            background url("../images/green2.png") no-repeat center;
                                            background-size 75% 75%;
                                        &.legend2
                                            background url("../images/green1.png") no-repeat center;
                                            background-size 75% 75%;
                                        &.legend3
                                            background url("../images/yellow1.png") no-repeat center;
                                            background-size 75% 75%;
                                        &.legend4
                                            background url("../images/red1.png") no-repeat center;
                                            background-size 75% 75%;
                                        .jdt
                                            width: 57%;
                                            height: 100%;
                                            // transform: rotate(-90deg);
                                            position: absolute;
                                            left: 0.43rem;
                                            top: 0rem;
                                            z-index: 2;
                                            .el-progress-bar__outer
                                                background #192F52
                                .jdt
                                    width: 57%;
                                    // transform: rotate(-90deg);
                                    position: absolute;
                                    left: 0.43rem;
                                    top: 78.5%;
                                    z-index: 2;
                                    .el-progress-bar__outer
                                        background #192F52
                                .value
                                    width 100%;
                                    text-align center;
                                    color  rgba(255,255,255,0.8);
                                    font-size 0.2rem;
                                    font-family technology
                                    padding-top: 0.08rem;
                                    position relative
                                    margin-top -.2rem
                                    z-index 3;
                                .split-val
                                    padding-right: 0.15rem;
                                    width: 100%;
                                    text-align: right;
                                    color: #fe0000;
                                    font-size: 0.12rem;
                                    position: absolute;
                                    right: 0.33rem;
                                    bottom: 0.3rem;
                                    z-index: 3;
                                    &:after
                                        display inline-block
                                        content "";
                                        width 0.12rem;
                                        height 0.02rem;
                                        background #F70806
                                        position absolute
                                        right 0;
                                        top 0.1rem;
                    >>> .el-carousel__indicators--outside
                        position: absolute;
                        bottom: -0.05rem;
                        left: 0;
                        right: 0;
                        display: flex;
                        justify-content: center;
                        padding: 0 20%;
                        .el-carousel__indicator--horizontal
                            flex 1;
                            max-width 0.6rem;
                            .el-carousel__button
                                height: 0.06rem;
                                background: #A8D7FB;
                                border-radius: 0.1rem;
            .graph-panel-2
                margin-top 0.15rem;
                flex 1;
                width 100%;
                .graph-panel-2-1
                    width 100%;
                    height calc(100% - 0.6rem);
                    .el-carousel
                        height 100%;
                        >>> .el-carousel__container
                            height calc(100% - 0.15rem)
                            .el-carousel__item
                                display flex
                                justify-content space-between
                                .panel-item
                                    width 49.5%;
                                    height 100%;
                                    background rgba(63, 116, 190, 0.2)
                                    display flex
                                    flex-direction column
                                    .panel-name
                                        margin 0.18rem auto;
                                        width: 2.07rem;
                                        height: 0.3rem;
                                        // box-shadow: 0rem 0rem 0.09rem 0rem rgba(109,212,0,1) inset;
                                        // color rgba(109, 212, 0, 1)
                                        font-size 0.18rem;
                                        display flex
                                        justify-content center
                                        align-items center
                                        color #01CBFF
                                        .span1
                                            background url("../images/jx1.png") no-repeat center;
                                            background-size 100% 100%;
                                            display: inline-block;
                                            width: .5rem;
                                            height: .2rem;
                                            margin-right: .1rem
                                        .span2
                                            background url("../images/jx2.png") no-repeat center;
                                            background-size 100% 100%;
                                            display: inline-block;
                                            width: .5rem;
                                            height: .2rem;
                                            margin-left .1rem
                                    .panel-content
                                        width 100%;
                                        height 100%
                                        flex 1;
                                        overflow-x auto
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        flex-direction: column;
                                        ul
                                            box-sizing border-box
                                            background url("../images/twoback.png") no-repeat center
                                            background-size 100% 40%
                                            background-position 0 85%
                                            width 100%;
                                            height 50%;
                                            padding 0 0.1rem 0.1rem 0.1rem
                                            display flex
                                            li
                                                width 0.78rem;
                                                height 100%;
                                                padding 0 0.02rem;
                                                display flex
                                                flex-direction column
                                                justify-content space-between
                                                align-items center
                                                flex-shrink:0
                                                position relative
                                                .name
                                                    width 100%;
                                                    height 0.21rem;
                                                    background rgba(63, 116, 190, 0.2);
                                                    display flex
                                                    justify-content center
                                                    align-items center
                                                    color rgba(255,255,255,0.6);
                                                    font-size 0.10rem;
                                                .bottom-name
                                                    width 84%;
                                                    height 0.21rem;
                                                    padding-left 0.04rem;
                                                    line-height 0.21rem;
                                                    text-align center
                                                    background #2C4C7B
                                                    color rgba(255,255,255,0.6);
                                                    font-size 0.10rem;
                                                    position: relative;
                                                    white-space nowrap
                                                    text-overflow ellipsis
                                                    overflow hidden
                                                    margin-top .1rem
                                                    &.bottom-name1
                                                        background url("../images/text1.png") no-repeat center;
                                                        background-size 100% 100%;
                                                    &.bottom-name2
                                                        background url("../images/text1.png") no-repeat center;
                                                        background-size 100% 100%;
                                                    &.bottom-name3
                                                        background url("../images/text3.png") no-repeat center;
                                                        background-size 100% 100%;
                                                    &.bottom-name4
                                                        background url("../images/text2.png") no-repeat center;
                                                        background-size 100% 100%;
                                                    // .tag
                                                    //     display inline-block;
                                                    //     width 0.5rem;
                                                    //     height 0.5rem;
                                                    //     background #21BC9A
                                                    //     transform rotate(45deg)
                                                    //     position: absolute;
                                                    //     left -0.5rem;
                                                    //     top -0.25rem;
                                                .view-box
                                                    box-sizing border-box
                                                    width 0.5rem;
                                                    height 0.9rem;
                                                    margin 0.1rem 0;
                                                    position relative
                                                    .view-legend
                                                        width 100%;
                                                        height 100%;
                                                        position absolute
                                                        left 0
                                                        top 0
                                                        z-index 1;
                                                    .view-legend01
                                                        background url("../images/twog1.png") no-repeat center;
                                                        background-size 100% 100%;
                                                    .view-legend02
                                                        background url("../images/twog2.png") no-repeat center;
                                                        background-size 100% 100%;
                                                    .view-legend03
                                                        background url("../images/twoy1.png") no-repeat center;
                                                        background-size 100% 100%;
                                                    .view-legend04
                                                        background url("../images/two4.png") no-repeat center;
                                                        background-size 100% 100%;
                                                    .jdt
                                                        width: 94%;
                                                        height: 100%;
                                                        // transform: rotate(-90deg);
                                                        position: absolute;
                                                        left: 0.2rem;
                                                        bottom: 0.05rem;
                                                        z-index: 2;
                                                        .el-progress-bar__outer
                                                            background rgba(88, 98, 112, 1)
                                                .jdt
                                                    width: 57%;
                                                    // transform: rotate(-90deg);
                                                    position: absolute;
                                                    left: .15rem;
                                                    top: 79%;
                                                    z-index: 2;
                                                    .el-progress-bar__outer
                                                        background #192F52
                                                .value
                                                    width 100%;
                                                    text-align center;
                                                    font-size 0.18rem;
                                                    font-weight: bold;
                                                    text-shadow: 0.02rem 0.02rem 0.03rem #000000;
                                                    -webkit-background-clip: text;
                                                    font-family technology
                                                    color #fff
                                                    padding-top: 0.08rem;
                                                    position relative
                                                    margin-top -.3rem
                                                    z-index 3;
                                                .split-val
                                                    padding-right: 0.15rem;
                                                    width: 100%;
                                                    text-align: right;
                                                    color: #fe0000;
                                                    font-size: 0.12rem;
                                                    position: absolute;
                                                    right: 0rem;
                                                    bottom: 0.15rem;
                                                    z-index: 3;
                                                    &:after
                                                        display inline-block
                                                        content "";
                                                        width 0.12rem;
                                                        height 0.02rem;
                                                        background #F70806
                                                        position absolute
                                                        right 0;
                                                        top 0.1rem;
                                        // ul.admixture-list
                                        //     margin-top 0.2rem;
                                        //     li
                                        //         .view-box
                                        //             width: 0.6rem;
                                        //             height: 0.88rem;
                                        //             padding 0;
                                        //             overflow hidden
                                        //             .view-legend-custom
                                        //                 position absolute
                                        //                 left 0;
                                        //                 top 0;
                                        //                 width 100%;
                                        //                 height calc(100% - 0.17rem);
                                        //                 z-index 3;
                                        //                 border: 1px solid rgba(185, 195, 214, 0.5);
                                        //                 border-bottom 0;
                                        //                 background rgba(12, 23, 43, 1);
                                        //                 .sanjiao
                                        //                     position absolute
                                        //                     left -1px
                                        //                     bottom -0.17rem
                                        //                     width 0;
                                        //                     height 0;
                                        //                     border-width 0.17rem 0.3rem 0;
                                        //                     border-style solid
                                        //                     border-color rgba(11, 163, 96, 1) transparent transparent
                                        //                     z-index 4;
                                        //                 .val-bg
                                        //                     position absolute
                                        //                     left 0
                                        //                     bottom 0
                                        //                     width 100%;
                                        //                     height 50%;
                                        //                     background rgba(11, 163, 96, 1)
                .nav-panel{
                    width 100%;
                    height: 0.61rem;
                    padding 0.05rem;
                    margin-top 0.07rem;
                    background: #11223C;
                    display flex
                    justify-content space-between
                    .nav-btn{
                        width: 0.46rem;
                        height: 0.46rem;
                        background: #20395F;
                        display flex
                        justify-content center
                        align-items center
                        cursor pointer
                        .iconfont{
                            font-size 22px;
                            color rgba(255, 255, 255, 0.2);
                        }
                        &.canUse .iconfont{
                            color rgba(255, 255, 255, 1);
                        }
                    }
                    .nav-list{
                        width calc(100% - 1rem)
                        height: 0.46rem;
                        background: #20395F;
                        padding 0 0.08rem;
                        display flex
                        justify-content center
                        align-items center
                        >div{
                            width calc(100%)
                            height .26rem
                            margin .1rem
                            display flex
                            align-items center;
                            border 1px solid #01CBFF
                            padding 0 .02rem
                            .nav-item{
                                flex 1;
                                height: 0.12rem;
                                background: #026f94;
                                margin 0 0.04rem;
                                display flex
                                justify-content center
                                align-items center;
                                color #fff
                                font-size 0.12rem;
                                &.active{
                                    background: #01CBFF;
                                    color #000
                                }
                            }
                        }
                    }
                }
</style>
